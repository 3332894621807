import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const LoadingSpinner = ({ visible = false, message = 'Please wait...' }) => {
  if (!visible) return null;

  return (
    <Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
      <h2 style={{ textAlign: 'center' }}>{message}</h2>
    </Box>
  );
};

LoadingSpinner.propTypes = {
  visible: PropTypes.bool,
  message: PropTypes.string,
};

export default LoadingSpinner;
