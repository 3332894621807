/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import appLogo from '@images/NAPA_LOGO.svg';
import userLogo from '@images/USER_LOGO.svg';
import userLogout from '@images/LOGOUT.svg';
import divider from '@images/NAV_DIVIDER.svg';
import REGULATIONS_CONSTANTS from '@constants/Regulations/Regulations';
import GENERIC_CONSTANTS from '@constants/Regulations/Generic';
import './Header.css';
import { AUTHX_LOGGGED_OUT } from '@services/utils/AuthxConstants';
import authClient from '@services/pingApi';
import { clearLoginToken, setAuthxStatus } from '@redux/loginTokenSlice';

const Header = () => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false);
  const { authxToken, authxStatus, userName } = useSelector(
    (state) => state.loginToken,
  );
  const dispatch = useDispatch();

  const handleLogoutClick = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const logout = () => {
    if (sessionStorage) {
      sessionStorage.clear();
    }
    if (authxToken) {
      authClient.signOff(authxToken.id_token, '');
    }
    dispatch(clearLoginToken());
    dispatch(setAuthxStatus(AUTHX_LOGGGED_OUT));
  };

  const handleLogout = () => {
    logout();
    setOpenDialog(false);
    navigate('/logout');
  };

  const hideLogoutButton = () => authxStatus === AUTHX_LOGGGED_OUT;

  return (
    <div>
      <div className="regulation-header">
        <span className="app-section">
          <img src={appLogo} alt="app-logo" className="app-logo" />
          <span className="regulation-title">
            {REGULATIONS_CONSTANTS.REGULATIONS.toUpperCase()}
          </span>
        </span>
        {userName && (
          <span className="user-section">
            <img src={userLogo} alt="user-logo" className="user-logo" />
            <span className="user-name">{userName}</span>
            <img src={divider} alt="divider" className="divider-icon" />
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
            <img
              src={userLogout}
              alt="user-logout"
              className="user-logout"
              onClick={handleLogoutClick}
              hidden={hideLogoutButton()}
            />
          </span>
        )}
      </div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="logout-dialog-description"
            className="logout-dialog-description"
          >
            <div className="logoutDialog">
              {GENERIC_CONSTANTS.LOGOUT_MESSAGE}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="logout-button-primary" onClick={handleLogout}>
            {GENERIC_CONSTANTS.YES}
          </Button>
          <Button className="logout-button-secondary" onClick={handleClose}>
            {GENERIC_CONSTANTS.CANCEL}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Header;
