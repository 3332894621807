// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Select,
  MenuItem,
  Typography,
  Divider,
  Button,
  Alert,
  Autocomplete,
  IconButton,
  Tooltip,
  InputAdornment,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import { DataGrid } from '@mui/x-data-grid';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom';

import REGULATIONS_CONSTANTS from '@constants/Regulations/Regulations';
import GENERIC_CONSTANTS from '@constants/Regulations/Generic';
import UPLOAD_CONSTANTS from '@constants/Regulations/PartsUpload';
import getApiHeaders from '@services/utils/apiHeaders';
import RegulationApi from '@services/RegulationsApi';
import DownloadTemplateButton from '@components/DownloadTemplateButton';
import UploadPartListButton from '@components/UploadPartListButton';
import {
  saveRegulationFormData,
  clearRegulationFormData,
  setNewRegulationData,
} from '@redux/regulationMaintenanceSlice';
import './addRegulations.css';
import getApiUrls from '@services/utils/apiUrls';
import REGULATIONS_API_BASE from '@services/axiosApi';
import RegionApi from '@services/RegionApi';
import LoadingSpinner from '@components/LoadingSpinner';
import MultiSelect from '@components/MultiSelect';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useSnackbar from '@hooks/useSnackbar';
import useToggle from '@hooks/useToggle';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import {
  useCities, useCounties, useStates, useRegions,
} from '@hooks/locations';
import { TextareaWithActions } from './TextareaWithActions';
import { DialogWithZipCodes } from './DialogWithZipCodes';
import {
  getFormattedDate,
  mergeAndHandleRegulationParts,
  getChunkParts,
  createChunkQuery,
} from '../utils/regulationUtils';
import { CreateRegionDialog } from './CreateRegionDialog';

// eslint-disable-next-line react/prop-types
const AddRegulations = ({ isHideDate = false }) => {
  const getBackendUrl = getApiUrls();
  const dispatch = useDispatch();
  const { regulationFormData, regulationParts, uploadPartsError } = useSelector(
    (state) => state.regulationMaintenanceForm,
  );
  const { userRoles, authxToken, userName } = useSelector(
    (state) => state.loginToken,
  );
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const [newRegulation, setNewRegulation] = useState(regulationFormData);
  const [activeStep, setActiveStep] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [showFormInvalid, setShowFormInvalid] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState('');
  const [showApiFailure, setShowApiFailure] = useState(false);
  const [apiFailureMessage, setApiFailureMessage] = useState('');
  const [showApiSuccess, setShowApiSuccess] = useState(false);
  const [apiSuccessMessage, setApiSuccessMessage] = useState('');
  const [showApiInfo, setShowApiInfo] = useState(false);
  const [apiInfoMessage, setApiInfoMessage] = useState('');
  const [partItemIds, setPartItemIds] = useState([]);
  const [filteredRegulatedParts, setFilteredRegulatedParts] = useState({});
  const [invalidRows, setInvalidRows] = useState(new Set());
  const [isValidationError, setIsValidationError] = useState(false);
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [zipCodes, setZipCodes] = useState('');
  const [showZipCodesDialog, setShowZipCodesDialog] = useState(false);
  const zipCodesToArray = useMemo(() => (
    zipCodes
      .split(',')
      .map((zip) => zip.replace('\n', ''))
      .map((zip) => zip.trim())
      .filter((zip) => zip !== '')
      .map(Number)
  ), [zipCodes]);
  const toggleZipCodesDialog = () => setShowZipCodesDialog((state) => !state);
  const { refetchRegions } = useRegions();

  const [showOnlyInvalidFields, setShowOnlyInvalidParts] = useState(false);

  const [showCreateRegionDialog, toggleCreateRegionDialog] = useToggle();

  const COUNTRY_CODE = 'US';
  const regulationStates = useStates(COUNTRY_CODE);
  const regulationCounties = useCounties(COUNTRY_CODE, newRegulation.stateCode);
  const regulationCities = useCities(COUNTRY_CODE, newRegulation.stateCode);

  const navigate = useNavigate();

  const handleFormUpdateByKey = (e, key) => {
    const formValue = e.target.value;

    if (key === 'jurisdictionLevel') {
      setSelectedRegion(null);
      setZipCodes('');
      setNewRegulation({
        ...newRegulation,
        other: null,
        regionId: null,
        county: '',
        counties: [],
        city: '',
        cities: [],
        stateCode: '',
        jurisdictionLevel: formValue,
        zipcodes: [],
      });
    } else {
      setNewRegulation({ ...newRegulation, [key]: formValue });
    }
  };

  const handleDatePickerSelection = (val) => {
    if (val) {
      const formattedEffDate = getFormattedDate(val);
      if (formattedEffDate !== 'Invalid Date') {
        setEffectiveDate(val);
        setNewRegulation({ ...newRegulation, effDate: formattedEffDate });
      }
    } else {
      setEffectiveDate('');
      setNewRegulation({ ...newRegulation, effDate: '' });
    }
  };

  const handleAlertClose = () => {
    setShowApiFailure(false);
    setApiFailureMessage('');
  };

  const callSaveRegulation = async () => {
    const headers = getApiHeaders(authxToken.access_token);
    const config = {
      ...headers,
    };
    const apiCallback = (response) => {
      if (response.status === 200) {
        if (response && response.data && response.data.regId) {
          setSpinner(false);
          dispatch(clearRegulationFormData());
          dispatch(setNewRegulationData({ value: response.data }));
        }
      } else {
        setSpinner(false);
        setShowApiFailure(true);
        setApiFailureMessage(REGULATIONS_CONSTANTS.SAVE_API_FAILURE);
      }
    };
    const newRegulationWithUserInfo = { ...newRegulation, createdBy: userName };
    await RegulationApi.saveRegulation(
      newRegulationWithUserInfo,
      config,
      apiCallback,
    );
  };

  const jurisdictionValue = newRegulation.jurisdictionLevel;
  const [COUNTRY, STATE, COUNTY, CITY, REGION] = REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS.map((opt) => opt.value);

  const isCountryJurisdiction = jurisdictionValue === COUNTRY;
  const isStateJurisdiction = jurisdictionValue === STATE;
  const isCountyJurisdiction = jurisdictionValue === COUNTY;
  const isCityJurisdiction = jurisdictionValue === CITY;
  const isRegionJurisdiction = jurisdictionValue === REGION;

  const isCountiesFieldDisabled = !isCountyJurisdiction || isCountryJurisdiction;
  const isCitiesFieldDisabled = !isCityJurisdiction || isCountyJurisdiction;

  const validateForm = (page) => {
    switch (page) {
      case 0:
        return isHideDate
          ? !!newRegulation.regTitle
          : !!(newRegulation.regTitle && newRegulation.effDate);

      case 1:
        if (isCountryJurisdiction || isStateJurisdiction) return true;
        if (isCountyJurisdiction) return !!newRegulation.county;
        if (isCityJurisdiction) return !!newRegulation.city;
        return isRegionJurisdiction
          ? !!(newRegulation.other && newRegulation.regionId)
          : false;

      case 2:
        return !!(newRegulation.parts && newRegulation.parts.length > 0);

      default:
        return false;
    }
  };

  const handleNavigation = (action) => {
    if (action === GENERIC_CONSTANTS.BACK) {
      setShowFormInvalid(false);
      const prevStep = activeStep - 1;
      setActiveStep(prevStep);
    } else {
      const isFormValid = validateForm(activeStep);
      if (isFormValid) {
        dispatch(saveRegulationFormData({ value: newRegulation }));
        if (activeStep === 2) {
          callSaveRegulation();
          setSpinner(true);
        } else {
          setShowFormInvalid(false);
          const nextStep = activeStep + 1;
          setActiveStep(nextStep);
        }
      } else {
        setShowFormInvalid(true);
      }
    }
  };

  const validateParts = (chunkParts, invalidRowsIds, catalogParts) => {
    const catalogValidatedParts = new Map();
    catalogParts.forEach((each) => {
      catalogValidatedParts.set(
        `${each.lineAbbreviation}-${each.partNumber}`,
        each,
      );
      catalogValidatedParts.set(
        `${each.hqAbbreviation}-${each.partNumber}`,
        each,
      );
    });

    chunkParts.forEach((fPart) => {
      const partId = `${fPart.lineAbbrev}-${fPart.partNo}`;
      const validPart = catalogValidatedParts.get(partId);
      const isAbbrevMismatch = validPart && validPart.lineAbbreviation !== fPart.lineAbbrev;

      if (!validPart || isAbbrevMismatch) {
        invalidRowsIds.add(partId);
      }

      // eslint-disable-next-line no-param-reassign
      fPart.partDesc = validPart ? validPart.title : fPart.partDesc;
    });
  };

  const isNextChunk = (index, chunkSize) => chunkSize > 0 && index % chunkSize === 0;

  function resetMessages() {
    setApiFailureMessage(UPLOAD_CONSTANTS.MESSAGES.EMPTY);
    setApiInfoMessage(UPLOAD_CONSTANTS.MESSAGES.EMPTY);
    setApiSuccessMessage(UPLOAD_CONSTANTS.MESSAGES.EMPTY);
  }

  function evaluateUploadStatus(invalidRowsIds) {
    const totalPartsCount = filteredRegulatedParts.parts.length;
    const invalidPartsCount = invalidRowsIds.size;

    if (invalidPartsCount === 0) {
      return UPLOAD_CONSTANTS.STATUS.SUCCESS;
    }

    if (invalidPartsCount === totalPartsCount) {
      return UPLOAD_CONSTANTS.STATUS.FAILURE;
    }

    return UPLOAD_CONSTANTS.STATUS.PARTIAL_FAILURE;
  }

  const showMessage = (status, invalidRowsIds, totalParts) => {
    resetMessages();

    switch (status) {
      case UPLOAD_CONSTANTS.STATUS.SUCCESS:
        setApiSuccessMessage(UPLOAD_CONSTANTS.MESSAGES.SUCCESS(totalParts));
        setShowApiSuccess(true);
        break;

      case UPLOAD_CONSTANTS.STATUS.FAILURE:
        setApiFailureMessage(
          UPLOAD_CONSTANTS.MESSAGES.FAILURE(invalidRowsIds.size),
        );
        setShowApiFailure(true);
        break;

      case UPLOAD_CONSTANTS.STATUS.PARTIAL_FAILURE:
        setApiFailureMessage(
          UPLOAD_CONSTANTS.MESSAGES.PARTIAL_FAILURE(
            invalidRowsIds.size,
            totalParts,
          ),
        );
        setShowApiFailure(true);
        break;

      default:
        break;
    }

    return true;
  };

  function resetPartsUploadState() {
    setIsValidationError(false);
    setShowApiFailure(false);
    setShowApiInfo(false);
    setShowApiSuccess(false);
    resetMessages();
    setShowOnlyInvalidParts(false);
    setInvalidRows(new Set());
  }

  const processCatalogResponses = (catalogPromises, invalidRowsIds) => {
    setSpinner(true);

    Promise.all(catalogPromises)
      .then(() => {
        setSpinner(false);
        setInvalidRows(invalidRowsIds);
        const status = evaluateUploadStatus(invalidRowsIds);
        showMessage(
          status,
          invalidRowsIds,
          filteredRegulatedParts.parts.length,
        );

        const updatedParts = {
          ...filteredRegulatedParts,
          parts: filteredRegulatedParts.parts,
        };
        setFilteredRegulatedParts(updatedParts);
      })
      .catch((error) => {
        setIsValidationError(true);
        setSpinner(false);
        setApiFailureMessage(
          error.message || UPLOAD_CONSTANTS.MESSAGES.UNEXPECTED_ERROR,
        );
        setShowApiFailure(true);
      });
  };

  const processItemLookUp = (response, catalogParts) => {
    const hasValidItems = (itemLookUp) => Boolean(itemLookUp?.items?.length);

    const { itemLookUp } = response.data.data;
    if (hasValidItems(itemLookUp)) {
      itemLookUp.items.forEach((item) => catalogParts.push(item));
    }
  };

  const createCatalogPromises = (invalidRowsIds) => {
    const catalogParts = [];
    const chunkSize = UPLOAD_CONSTANTS.CHUNK_SIZE;
    const headers = getApiHeaders(authxToken.access_token);
    return partItemIds.reduce((promises, _, index) => {
      if (isNextChunk(index, chunkSize)) {
        const queryData = createChunkQuery(partItemIds, chunkSize, index);
        promises.push(
          REGULATIONS_API_BASE.post(
            `${getBackendUrl}${UPLOAD_CONSTANTS.CATALOG_PATH}`,
            {
              query: queryData,
            },
            { ...headers },
          ).then((response) => {
            if (response.data.errors) {
              throw new Error(UPLOAD_CONSTANTS.MESSAGES.CATALOG_ERROR);
            }

            if (response.data.data?.itemLookUp) {
              processItemLookUp(response, catalogParts);

              const chunkParts = getChunkParts(
                filteredRegulatedParts.parts,
                partItemIds,
                chunkSize,
                index,
              );
              validateParts(chunkParts, invalidRowsIds, catalogParts);
            }
          }),
        );
      }
      return promises;
    }, []);
  };

  const handleRegionChange = (_, region) => {
    if (!region) {
      setSelectedRegion(null);
      setZipCodes('');
      setNewRegulation({
        ...newRegulation,
        regionId: null,
        other: null,
        stateCode: null,
        city: null,
        cities: [],
        county: null,
        counties: [],
        zipcodes: null,
      });

      return;
    }

    const {
      regionId,
      regionName,
      countryCode,
      stateCode,
      cities,
      counties,
      zipcodes,
    } = region;

    if (Array.isArray(zipcodes)) {
      setZipCodes(zipcodes.join(', '));
    }

    setSelectedRegion(region);
    setNewRegulation({
      ...newRegulation,
      other: regionName,
      regionId,
      countryCode,
      stateCode,
      cities,
      counties,
      zipcodes,
    });
  };

  useEffect(() => {
    RegionApi.getRegions(authxToken.access_token)
      .then(({ data }) => setRegions(data))
      .catch((err) => { throw new Error(err); });
  }, []);

  useEffect(() => {
    if (partItemIds?.length > 0) {
      const invalidRowsIds = new Set();
      const catalogPromises = createCatalogPromises(invalidRowsIds);
      resetPartsUploadState();
      processCatalogResponses(catalogPromises, invalidRowsIds);
    }
  }, [partItemIds]);

  useEffect(() => {
    if (
      uploadPartsError
      && Object.keys(uploadPartsError)[0] === 'regulationParts'
      && uploadPartsError.regulationParts
    ) {
      const errorMsg = uploadPartsError.regulationParts
        ? uploadPartsError.regulationParts
        : REGULATIONS_CONSTANTS.UNABLE_READ_FILE;
      setApiFailureMessage(errorMsg);
      setShowApiFailure(true);
    }
  }, [uploadPartsError]);

  const generatePartItemIds = () => {
    const regulatedPartsPID = regulationParts.map((each) => (each.lineAbbrev.length === 2
      ? `${each.lineAbbrev}_${each.partNo}`
      : each.lineAbbrev + each.partNo));
    setPartItemIds(regulatedPartsPID);
  };

  useEffect(() => {
    if (regulationParts && regulationParts.length > 0) {
      const mergedParts = mergeAndHandleRegulationParts([], regulationParts);

      setNewRegulation({ ...newRegulation, parts: mergedParts });
      setShowFormInvalid(false);
      setFilteredRegulatedParts({
        ...newRegulation,
        parts: mergedParts,
      });

      handleAlertClose();
      generatePartItemIds();
    } else if (regulationParts && regulationParts.length === 0) {
      setNewRegulation({ ...newRegulation, parts: regulationParts });
    }
  }, [regulationParts]);

  useEffect(() => {
    if (userRoles && !userRoles.includes('Block_Sale_RW')) {
      navigate('/landing');
    }
  }, []);

  const partsTableColumns = useMemo(
    () => [
      {
        field: REGULATIONS_CONSTANTS.PART_ABBR,
        headerName: REGULATIONS_CONSTANTS.FIELD_LINE,
        width: 150,
      },
      {
        field: REGULATIONS_CONSTANTS.PART_NO,
        headerName: REGULATIONS_CONSTANTS.PART_NUMBER,
        width: 150,
      },
      {
        field: REGULATIONS_CONSTANTS.PART_DESC,
        headerName: REGULATIONS_CONSTANTS.PART_DESCRIPTION,
        width: 300,
      },
    ],
    [newRegulation.parts],
  );

  const getRowClassName = (params) => (invalidRows.has(params.row.id) ? 'inValidRow' : '');

  const handleZipCodesCopy = async (input) => {
    if (!input) {
      return;
    }

    if (Array.isArray(input) && !input.length) {
      return;
    }

    try {
      const textToCopy = typeof input === 'number' ? input : input.join(', ');
      await navigator.clipboard.writeText(textToCopy);
      showSuccessSnackbar('Text copied to clipboard!');
    } catch (err) {
      showErrorSnackbar('Something went wrong. Failed to copy text.');
    }
  };

  const handleNewRegionCreated = async (region) => {
    toggleCreateRegionDialog();
    setRegions((prevRegions) => [...prevRegions, region]);
    handleRegionChange(null, region);
    await refetchRegions();
  };

  const uploadedParts = useMemo(() => {
    if (!showOnlyInvalidFields) {
      return newRegulation.parts;
    }

    return newRegulation.parts.filter((row) => invalidRows.has(row.id));
  }, [newRegulation.parts, showOnlyInvalidFields, invalidRows, regulationParts]);

  return (
    <div className="add-regulations-container">
      <DialogWithZipCodes
        open={showZipCodesDialog}
        onClose={toggleZipCodesDialog}
        zipCodes={zipCodesToArray}
        onCopy={handleZipCodesCopy}
      />

      <CreateRegionDialog
        open={showCreateRegionDialog}
        onCopyZIPCodes={handleZipCodesCopy}
        onClose={toggleCreateRegionDialog}
        onSubmit={handleNewRegionCreated}
      />

      {showApiFailure && (
        <Alert
          severity="error"
          variant="outlined"
          sx={{ padding: '25px', margin: '20px' }}
          onClose={handleAlertClose}
        >
          {apiFailureMessage}
        </Alert>
      )}
      {showApiSuccess && (
        <Alert
          severity="success"
          variant="outlined"
          sx={{ padding: '25px', margin: '20px' }}
          onClose={handleAlertClose}
        >
          {apiSuccessMessage}
        </Alert>
      )}
      {showApiInfo && (
        <Alert
          severity="info"
          variant="outlined"
          sx={{ padding: '25px', margin: '20px' }}
          onClose={handleAlertClose}
        >
          {apiInfoMessage}
        </Alert>
      )}
      <LoadingSpinner visible={spinner} />
      {!spinner && (
        <div>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {REGULATIONS_CONSTANTS.STEPPER_TITLES.map((label) => (
                <Step key={label}>
                  <StepLabel className="stepper-label">{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <div className="add-regulation-form">
              <Typography
                variant="h5"
                gutterBottom
                className="stepper-content-title"
              >
                {REGULATIONS_CONSTANTS.STEPPER_TITLES[activeStep]}
                <p className="stepper-content-subTitle">
                  {REGULATIONS_CONSTANTS.STEPPER_SUB_TITLES[activeStep]}
                </p>
              </Typography>
              <Divider />
              {showFormInvalid && (
                <Alert severity="error">
                  {activeStep === 2
                    ? REGULATIONS_CONSTANTS.UPLOAD_PARTS_ERROR
                    : REGULATIONS_CONSTANTS.FORM_VALIDITY_ERROR}
                </Alert>
              )}
              {activeStep === 0 && (
                <div>
                  <FormControl
                    sx={{ m: 1, width: '100%', backgroundColor: '#fff' }}
                  >
                    <FormLabel required sx={{ m: 1 }}>
                      {REGULATIONS_CONSTANTS.REGULATION_HEADER_LABELS[0]}
                    </FormLabel>
                    <TextField
                      id="new-reg-title"
                      type="text"
                      variant="outlined"
                      value={newRegulation.regTitle}
                      inputProps={{ maxLength: 40 }}
                      helperText="Title must be below 40 charecters"
                      onChange={(e) => handleFormUpdateByKey(e, 'regTitle')}
                    />
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, width: '100%', backgroundColor: '#fff' }}
                  >
                    <FormLabel className="stepper-form-label" sx={{ m: 1 }}>
                      {
                        REGULATIONS_CONSTANTS
                          .REGULATION_DETAILS_HEADER_LABELS[0]
                      }
                    </FormLabel>
                    <TextareaAutosize
                      id="new-reg-desc"
                      aria-label="empty textarea"
                      minRows={5}
                      placeholder="Describe about the regulation"
                      variant="outlined"
                      value={newRegulation.regDesc}
                      onChange={(e) => handleFormUpdateByKey(e, 'regDesc')}
                    />
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, width: '100%', backgroundColor: '#fff' }}
                  >
                    <FormLabel className="stepper-form-label" sx={{ m: 1 }}>
                      {REGULATIONS_CONSTANTS.REGULATION_RULE}
                    </FormLabel>
                    <TextField
                      id="new-reg-ruleNo"
                      type="text"
                      variant="outlined"
                      value={newRegulation.ruleNo}
                      onChange={(e) => handleFormUpdateByKey(e, 'ruleNo')}
                    />
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, width: '100%', backgroundColor: '#fff' }}
                  >
                    <FormLabel required sx={{ m: 1 }}>
                      {REGULATIONS_CONSTANTS.RES_CODE}
                    </FormLabel>
                    <Select
                      id="new-reg-type"
                      value={newRegulation.regType}
                      onChange={(e) => handleFormUpdateByKey(e, 'regType')}
                    >
                      <MenuItem value="Banned">
                        {REGULATIONS_CONSTANTS.BANNED}
                      </MenuItem>
                      <MenuItem value="Restricted">
                        {REGULATIONS_CONSTANTS.RESTRICTED}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {!isHideDate && (
                    <FormControl
                      sx={{ m: 1, width: '100%', backgroundColor: '#fff' }}
                    >
                      <FormLabel required sx={{ m: 1 }}>
                        {
                          REGULATIONS_CONSTANTS
                            .REGULATION_DETAILS_HEADER_LABELS[1]
                        }
                      </FormLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label=""
                          value={effectiveDate}
                          onChange={handleDatePickerSelection}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  )}
                </div>
              )}
              {activeStep === 1 && (
                <div>
                  <FormControl
                    sx={{ m: 1, width: '100%', backgroundColor: '#fff' }}
                  >
                    <FormLabel required sx={{ m: 1 }}>
                      {REGULATIONS_CONSTANTS.JURISDICTION_LEVEL}
                    </FormLabel>
                    <Select
                      id="new-reg-level"
                      value={newRegulation.jurisdictionLevel}
                      onChange={(e) => handleFormUpdateByKey(e, 'jurisdictionLevel')}
                    >
                      {REGULATIONS_CONSTANTS.REG_LEVEL_OPTIONS.map((each) => (
                        <MenuItem
                          key={`reg-level-${each.label}`}
                          value={each.value}
                        >
                          {each.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {isRegionJurisdiction && (
                    <FormControl sx={{ m: 1, width: '100%' }} required>
                      <FormLabel required sx={{ m: 1 }}>
                        {REGULATIONS_CONSTANTS.REGION}
                      </FormLabel>
                      <Autocomplete
                        disablePortal
                        id="new-reg-select-region"
                        options={regions}
                        getOptionLabel={(option) => option?.regionName}
                        getOptionKey={(option) => option?.regionId}
                        fullWidth
                        renderInput={
                          (params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {params.InputProps.endAdornment}
                                    <InputAdornment position="end">
                                      <Tooltip
                                        title={REGULATIONS_CONSTANTS.CREATE_NEW_REGION}
                                        placement="top-start"
                                      >
                                        <IconButton onClick={toggleCreateRegionDialog}>
                                          <AddIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </InputAdornment>
                                  </>
                                ),
                              }}
                            />
                          )
                        }
                        value={selectedRegion}
                        onChange={handleRegionChange}
                      />
                    </FormControl>
                  )}
                  <FormControl
                    sx={{ m: 1, width: '100%', backgroundColor: '#fff' }}
                  >
                    <FormLabel required sx={{ m: 1 }}>
                      {GENERIC_CONSTANTS.COUNTRY}
                    </FormLabel>
                    <Select
                      id="new-reg-country"
                      value={newRegulation.countryCode}
                      disabled={isRegionJurisdiction}
                    >
                      <MenuItem value={newRegulation.countryCode}>
                        {newRegulation.countryCode}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ m: 1, width: '100%', backgroundColor: '#fff' }}
                    disabled={isCountryJurisdiction}
                  >
                    <FormLabel
                      required={!isCountryJurisdiction}
                      sx={{ m: 1 }}
                    >
                      {GENERIC_CONSTANTS.STATE}
                    </FormLabel>
                    <Select
                      id="new-reg-state"
                      value={isCountryJurisdiction ? '' : newRegulation.stateCode}
                      onChange={(e) => handleFormUpdateByKey(e, 'stateCode')}
                      disabled={isRegionJurisdiction || isCountryJurisdiction}
                    >
                      {regulationStates?.map((stateCode) => (
                        <MenuItem key={stateCode} value={stateCode}>
                          {stateCode}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {isRegionJurisdiction ? (
                    <FormControl
                      sx={{ m: 1, width: '100%', backgroundColor: '#fff' }}
                      disabled={isCountiesFieldDisabled}
                    >
                      <FormLabel
                        required={isCountyJurisdiction}
                        sx={{ m: 1 }}
                      >
                        {GENERIC_CONSTANTS.COUNTIES}
                      </FormLabel>
                      <MultiSelect
                        options={regulationCounties ?? []}
                        value={newRegulation.counties}
                        readOnly={isCountiesFieldDisabled}
                        onChange={
                          (counties) => setNewRegulation(
                            (regulation) => ({ ...regulation, counties }),
                          )
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl
                      sx={{ m: 1, width: '100%', backgroundColor: '#fff' }}
                      disabled={isCountiesFieldDisabled}
                    >
                      <FormLabel
                        required={isCountyJurisdiction}
                        sx={{ m: 1 }}
                      >
                        {GENERIC_CONSTANTS.COUNTY}
                      </FormLabel>
                      <Select
                        id="new-reg-county"
                        value={newRegulation.county}
                        onChange={(e) => handleFormUpdateByKey(e, 'county')}
                      >
                        {regulationCounties?.map((county) => (
                          <MenuItem key={county} value={county}>
                            {county}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {isRegionJurisdiction ? (
                    <FormControl
                      sx={{ m: 1, width: '100%', backgroundColor: '#fff' }}
                      disabled={isCitiesFieldDisabled}
                    >
                      <FormLabel
                        required={isCityJurisdiction}
                        sx={{ m: 1 }}
                      >
                        {GENERIC_CONSTANTS.CITIES}
                      </FormLabel>
                      <MultiSelect
                        options={regulationCities ?? []}
                        value={newRegulation.cities}
                        readOnly={isCitiesFieldDisabled}
                        onChange={
                          (cities) => setNewRegulation(
                            (regulation) => ({ ...regulation, cities }),
                          )
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl
                      sx={{ m: 1, width: '100%', backgroundColor: '#fff' }}
                      disabled={isCitiesFieldDisabled}
                    >
                      <FormLabel
                        required={isCityJurisdiction}
                        sx={{ m: 1 }}
                      >
                        {GENERIC_CONSTANTS.CITY}
                      </FormLabel>
                      <Select
                        id="new-reg-city"
                        value={newRegulation.city}
                        onChange={(e) => handleFormUpdateByKey(e, 'city')}
                      >
                        {regulationCities?.map((city) => (
                          <MenuItem key={city} value={city}>
                            {city}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <Box sx={{ margin: 1 }}>
                    <TextareaWithActions
                      value={zipCodes}
                      disabled
                      onChange={(e) => setZipCodes(e.target.value)}
                      label={GENERIC_CONSTANTS.ZIP_CODES}
                      actions={zipCodes && (
                        <Tooltip title={GENERIC_CONSTANTS.COPY_ALL_ZIP_CODES} placement="top-start">
                          <IconButton
                            sx={{ padding: 0 }}
                            onClick={() => handleZipCodesCopy(zipCodesToArray)}
                          >
                            <CopyAllIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      actionsOnOverflow={(
                        <Tooltip
                          title={GENERIC_CONSTANTS.SHOW_ALL_ZIP_CODES}
                          placement="top-start"
                        >
                          <IconButton
                            sx={{ padding: 0 }}
                            onClick={toggleZipCodesDialog}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    />
                  </Box>
                </div>
              )}
              {activeStep === 2 && (
                <div className="regulated-parts-section">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Box display="flex">
                      <DownloadTemplateButton />
                      <UploadPartListButton />
                    </Box>

                    <Box>
                      {invalidRows.size > 0 && (
                        <FormControlLabel
                          label={REGULATIONS_CONSTANTS.SHOW_INVALID_ONLY}
                          control={(
                            <Switch
                              size="small"
                              checked={showOnlyInvalidFields}
                              onChange={(_, checked) => setShowOnlyInvalidParts(checked)}
                            />
                          )}
                        />
                      )}
                    </Box>
                  </Box>
                  {newRegulation.parts && newRegulation.parts.length > 0 && (
                    <div className="uploaded-parts-table">
                      <DataGrid
                        rows={uploadedParts}
                        columns={partsTableColumns}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick
                        disableColumnSelector
                        disableColumnMenu
                        autoHeight
                        classes={{ withBorderColor: '#001489' }}
                        getRowClassName={getRowClassName}
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="stepper-actions">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {activeStep !== 0 && (
                    <Button
                      className="back-btn"
                      variant="outlined"
                      startIcon={<ArrowBackIosIcon />}
                      onClick={() => handleNavigation('Back')}
                    >
                      {GENERIC_CONSTANTS.BACK}
                    </Button>
                  )}
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <Button
                    className={
                      (invalidRows.size > 0 || isValidationError)
                        && activeStep === 2
                        ? 'continue-btn-disabled'
                        : 'continue-btn'
                    }
                    variant="contained"
                    onClick={() => handleNavigation('continue')}
                    disabled={
                      (invalidRows.size > 0 || isValidationError)
                      && activeStep === 2
                    }
                    endIcon={<ArrowForwardIosIcon />}
                  >
                    {activeStep === 2
                      ? GENERIC_CONSTANTS.SUBMIT
                      : GENERIC_CONSTANTS.SAVE_CONTINUE}
                  </Button>
                </Grid>
              </div>
            </div>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default AddRegulations;
