import { useState, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  Box,
  TextField,
  List,
  ListItemButton,
  ListItemText,
  DialogContent,
  Button,
  Typography,
} from "@mui/material";
import GENERIC_CONSTANTS from '@constants/Regulations/Generic';

type DialogWithZipCodesProps = {
  open: boolean,
  zipCodes: number[];
  onClose: () => void,
  onCopy: (zipCodes: number[] | number) => void;
};

export const DialogWithZipCodes: React.FC<DialogWithZipCodesProps> = ({
  open,
  zipCodes,
  onClose,
  onCopy,
}) => {
  const [search, setSearch] = useState('');
  const [hoverIndex, setHoverIndex] = useState<null | number>(null);

  const filteredZipCodes = useMemo(() => {
    if (!search) {
      return zipCodes;
    }

    const lowerTrimmedSearch = search.toLowerCase().trim();

    return zipCodes.filter(
      (code) => code
        .toString()
        .toLowerCase()
        .includes(lowerTrimmedSearch)
    );
  }, [zipCodes, search]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{ height: '700px' }}
      scroll="paper"
    >
      <DialogTitle>
        {GENERIC_CONSTANTS.ALL_ZIP_CODES}
        {filteredZipCodes.length > 0 && ` (${filteredZipCodes.length})`}
      </DialogTitle>
      <Box padding={2}>
        <TextField
          fullWidth
          placeholder={GENERIC_CONSTANTS.SEACRH_ZIP_CODES}
          variant="outlined"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <DialogContent>
        <List>
          {filteredZipCodes.map((code, index) => (
            <ListItemButton
              key={`${index}-${code}`}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
              onClick={() => onCopy(code)}
              sx={{
                backgroundColor: index % 2 === 0 ? 'grey.200' : 'inherit',
                "&:hover": { backgroundColor: 'grey.300' },
                cursor: 'pointer',
              }}
            >
              <ListItemText primary={code} />
              {hoverIndex === index && (
                <Button
                  size="small"
                  sx={{
                    textTransform: 'none',
                    fontSize: '0.8rem',
                    color: 'grey.500'
                  }}
                  onClick={() => onCopy(code)}
                >
                  Copy
                </Button>
              )}
            </ListItemButton>
          ))}
          {!filteredZipCodes.length && <Typography align='center'>{GENERIC_CONSTANTS.NO_ZIP_CODES}</Typography>}
        </List>
      </DialogContent>
    </Dialog>
  );
};
