import { useState, useEffect, useCallback } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import COMPONENTS_CONSTANTS from '@constants/Components/Generic'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

type Option = { label: string, value: string };

type MultiSelectProps<T = Option | string> = {
  options: T[];
  id?: string;
  name?: string;
  value?: T[];
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (selectedValues: T[]) => void;
  getOption?: (option: T) => string;
  getKey?: (option: T) => string;
};

type SortOptionsFunc = (
  params: Pick<MultiSelectProps, 'options' | 'value' | 'getKey'>
) => MultiSelectProps['options']

const MultiSelect = <T extends Option | string>({
  options,
  id,
  name,
  value,
  disabled,
  readOnly,
  onChange,
  getOption,
  getKey,
}: MultiSelectProps<T>) => {
  const [internalValue, setInternalValue] = useState<T[]>(value ?? []);
  const [displayOptions, setDisplayOptions] = useState<T[]>(options ?? [] as T[]);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)

  useEffect(() => {
    if (value) {
      setInternalValue(value);
    }
  }, [value]);

  useEffect(() => {
    setDisplayOptions(options ?? [] as T[]);
  }, [options])

  const effectiveGetOption = useCallback(
    getOption ?? ((option: T) => (typeof option === 'string' ? option : option.label)),
    [getOption]
  );

  const effectiveGetKey = useCallback(
    getKey ?? ((option: T) => (typeof option === 'string' ? option : option.value)),
    [getKey]
  );

  useEffect(() => {
    const seen = new Set<string>();
    const duplicates = options.filter((option) => {
      const key = effectiveGetKey(option);

      if (seen.has(key)) {
        return true;
      }

      seen.add(key);
      return false;
    });

    if (duplicates.length > 0) {
      console.warn(
        COMPONENTS_CONSTANTS.MULTISELECT_UNIQE_ITEMS_WARNING,
        duplicates
      );
    }
  }, [options, effectiveGetKey]);

  const sortOptions: SortOptionsFunc = useCallback(({
    options = [],
    value = [],
    getKey = (option) => option,
  }) => {
    const selectedKeys = new Set(value.map(getKey));

    return [...options].sort((a, b) => {
      const aSelected = selectedKeys.has(getKey(a));
      const bSelected = selectedKeys.has(getKey(b));

      if (aSelected === bSelected) {
        return 0;
      }

      return aSelected ? -1 : 1;
    });
  }, [internalValue, options, effectiveGetKey]);

  const handleChange = (_: React.ChangeEvent<{}>, values: T[]) => {
    setInternalValue(values);
    onChange?.(values);
  };

  const handleDropdownOpen = () => setIsDropdownOpen(true);

  const handleDropdownClose = (_: React.ChangeEvent<{}>, reason: string) => {
    if (reason === 'escape' || reason === 'blur') {
      setIsDropdownOpen(false);
      setDisplayOptions(
        (options) => sortOptions({
          options,
          value: internalValue,
          getKey: effectiveGetKey as MultiSelectProps['getKey'],
        }) as T[],
      );
    }
  }

  return (
    <Autocomplete
      multiple
      id={id}
      open={isDropdownOpen}
      onOpen={handleDropdownOpen}
      onClose={handleDropdownClose}
      options={displayOptions}
      value={internalValue}
      getOptionLabel={effectiveGetOption}
      isOptionEqualToValue={(option, value) => effectiveGetKey(option) === effectiveGetKey(value)}
      onChange={handleChange}
      readOnly={readOnly}
      disabled={disabled}
      limitTags={4}
      sx={{
        ...(readOnly && {
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#d1d1d1 !important',
              borderWidth: '1px !important',
            },
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#d1d1d1 !important',
            borderWidth: '1px !important',
          },
          '&:focus, &:focus-visible, & .Mui-focused': {
            outline: 'none !important',
          },
        }),
      }}
      renderInput={(params) => <TextField name={name} {...params} type="text" variant="outlined" />}
      popupIcon={
        <ArrowDropDownIcon
          sx={(theme) => ({
            color: readOnly ? theme.palette.action.disabled : 'inherit',
          })}
        />
      }
    />
  );
};


export default MultiSelect;
