import dayjs from 'dayjs';
import REGULATIONS_API_BASE from './axiosApi';

const handleApiResponse = (response, callback) => {
  if (response?.status === 200) {
    callback(response);
  }
  return response;
};

const handleApiError = (error, callback) => {
  callback(error);
};

const RegulationApi = {
  getRegulations: (apiInput, apiCallback) => {
    const apiUrl = '/proxy/v1/regulations/paged';
    REGULATIONS_API_BASE.get(apiUrl, apiInput)
      .then((response) => handleApiResponse(response, apiCallback))
      .catch((error) => handleApiError(error, apiCallback));
  },
  getGeolocationDataByParam: (apiInput, apiCallback) => {
    const apiUrl = '/proxy/v1/geolocation';
    return REGULATIONS_API_BASE.get(apiUrl, apiInput)
      .then((response) => handleApiResponse(response, apiCallback))
      .catch((error) => handleApiError(error, apiCallback));
  },
  getFormattedDate: (dateVal) => {
    if (dayjs && typeof dayjs === 'function') {
      return dayjs(dateVal).format('YYYY-MM-DD');
    }
    return dateVal;
  },
  saveRegulation: (
    apiInput,
    config,
    apiCallback,
    action = 'save',
    updatedParts = [],
  ) => {
    const apiUrl = '/proxy/v1/save-regulation';
    const parts = [];
    if (action === 'save') {
      apiInput.parts.forEach((part, index) => {
        const obj = {
          ...apiInput.parts[index],
          partDesc: apiInput.parts[index].partDesc?.substr(0, 39),
          createdBy: apiInput.createdBy,
        };
        parts.push(obj);
      });
    }

    const regData = {};

    const formatPart = (part) => {
      const p = { ...part, partDesc: part.partDesc?.substr(0, 39) };
      delete p.modifiedDate;
      delete p.createdDate;
      return p;
    };

    const handlePartsKey = () => {
      const key = 'parts';

      if (action === 'save') {
        regData[key] = parts;
        return;
      }

      if (updatedParts.length === 0) {
        regData[key] = updatedParts;
        return;
      }

      const uParts = updatedParts.length ? updatedParts : [];
      regData[key] = uParts.length ? uParts.map(formatPart) : apiInput[key];
    };

    const handleModifiedFields = (key) => {
      if (apiInput[key] != null && apiInput[key] !== '') {
        regData[key] = apiInput[key];
      }
    };

    Object.keys(apiInput).forEach((key) => {
      switch (key) {
        case 'parts':
          handlePartsKey();
          break;
        case 'modifiedBy':
        case 'modifiedDate':
          handleModifiedFields(key);
          break;
        default:
          regData[key] = apiInput[key];
      }
    });

    delete regData.modifiedDate;
    delete regData.createdDate;
    const rData = { ...regData };
    return REGULATIONS_API_BASE.post(
      apiUrl,
      { ...regData, parts: rData.parts },
      config,
    )
      .then((response) => handleApiResponse(response, apiCallback))
      .catch((error) => handleApiError(error, apiCallback));
  },
};

export default RegulationApi;
