import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NoAccessPage from '@components/NoAccessPage';
import { useAuthxTokenCheck } from '@services/loginApi';
import {
  AUTHX_PENDING,
  AUTHX_DENIED,
  AUTHX_ALLOWED,
  AUTHX_LOGGGED_OUT,
  AUTHX_ERROR,
} from '@services/utils/AuthxConstants';

const renderComponent = (item) => {
  switch (item) {
    case AUTHX_PENDING:
    case AUTHX_DENIED:
    case AUTHX_ERROR:
    case AUTHX_LOGGGED_OUT:
      return <NoAccessPage noAccessReason={item} />;
    case AUTHX_ALLOWED:
      return <Outlet />;
    default:
      return <NoAccessPage noAccessReason={AUTHX_PENDING} />;
  }
};

const Authx = () => {
  const { authxStatus } = useSelector((state) => state.loginToken);

  const [searchParams] = useSearchParams();
  const pingCode = searchParams.get('code');
  const pingErr = searchParams.get('error');
  const location = useLocation();
  const iPath = location.pathname;

  useAuthxTokenCheck(pingCode, pingErr, iPath);

  return <>{renderComponent(authxStatus)}</>;
};

export default Authx;
