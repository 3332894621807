import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { fetchRegions, type Region } from "api/locations";

type UseRegionsResult = {
  regions: Region[] | null;
  error: UseQueryResult['error'];
  refetchRegions: UseQueryResult['refetch'];
};

export const useRegions = (): UseRegionsResult => {
  const { data, error, refetch } = useQuery({
    queryKey: ['geolocation', 'regions'],
    queryFn: fetchRegions,
    staleTime: 1_000 * 60 * 60 * 24,
  });

  return {
    regions: data ?? null,
    error: error,
    refetchRegions: refetch,
  };
};
